import { backendConfig } from "../constants/authConfig";

export function getAllAttendee(accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const options = {
    method: "POST",
    headers: headers,
  };

  return fetch(
    `${backendConfig.backendEndpoint}attendee-getallattendee`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

//Commenting the function as signature is removed as part of the CR#CHG2429936*
// export function getSignatureByAttendeeId(accessToken, attendee_id) {
//   const headers = new Headers();
//   // const bearer = `Bearer ${accessToken}`;
//   headers.append("Content-Type", "application/json");
//   headers.append("authorizationtoken", accessToken);
//   const data = JSON.stringify({
//     attendee_id,
//   });
//   const options = {
//     method: "POST",
//     headers: headers,
//     body: data,
//   };

//   return fetch(`${backendConfig.backendEndpoint}attendee-getsignature`, options)
//     .then((response) => response.json())
//     .catch((error) => console.log(error));
// }
export function getExportData(accessToken, filters, data, limit) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const exportdata = JSON.stringify({
    start_date: filters.start_date,
    end_date: filters.end_date,
    meeting_id: filters.meeting_id,
    national_id: filters.national_id,
    professional_id: filters.professional_id,
    lilly_id: filters.lilly_id,
    offsetdata: data,
    limitdata: limit,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: exportdata,
  };

  return fetch(`${backendConfig.backendEndpoint}attendee-exportExcel`, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function getFilteredAttendee(accessToken, data) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const attendeeData = JSON.stringify({
    start_date: data.start_date,
    end_date: data.end_date,
    meeting_id: data.meeting_code,
    national_id: data.national_id,
    professional_id: null,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: attendeeData,
  };
  return fetch(
    `${backendConfig.backendEndpoint}attendee-filterallattendee`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export function getAttendeeDetailsById(data, accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const meetingData = JSON.stringify({
    meeting_id: data.meeting_id,
    id: data.attendee_id,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: meetingData,
  };

  return fetch(
    `${backendConfig.backendEndpoint}attendee-getallattendee`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export function getFilteredAttendeeDetailsById(accessToken, data) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const attendeeData = JSON.stringify({
    start_date: data.start_date,
    end_date: data.end_date,
    meeting_id: data.meeting_code,
    national_id: data.national_id,
    professional_id: null,
    lilly_id: data.lilly_id,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: attendeeData,
  };
  return fetch(
    `${backendConfig.backendEndpoint}attendee-filterallattendeebylillyid`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export function getAttendeeDetailsByLillyId(data, accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const meetingData = JSON.stringify({
    lilly_id: data.lilly_id,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: meetingData,
  };

  return fetch(
    `${backendConfig.backendEndpoint}attendee-getattendeedetailsbylillyid`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export function addAttendeeDetails(data, accessToken) {
  const headers = new Headers();
  // const bearer = `Bearer ${accessToken}`;
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const attendeeData = JSON.stringify({
    customer_id: data.customer_id,
    enterprise_id: data.enterprise_id,
    hcp_firstname: data.hcp_firstname,
    hcp_lastname: data.hcp_lastname,
    meeting_id: data.meeting_id,
    national_id: data.national_id,
    professional_id: data.professional_id,
    hcp_specialization: data.hcp_specialization,
    hcp_signature: data.hcp_signature,
    hcp_signature_filename: data.hcp_signature_filename,
    email_id: data.email_id,
    hcp_mobile: data.hcp_mobile,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: attendeeData,
  };

  return fetch(
    `${backendConfig.backendEndpoint}attendee-addattendeedetails`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export function updateAttendeeDetails(data, accessToken) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("authorizationtoken", accessToken);
  const attendeeData = JSON.stringify({
    customer_id: data.customer_id,
    enterprise_id: data.enterprise_id,
    id: data.attendee_id,
    hcp_firstname: data.hcp_firstname,
    hcp_lastname: data.hcp_lastname,
    meeting_id: data.meeting_id,
    national_id: data.national_id,
    professional_id: data.professional_id,
    hcp_specialization: data.hcp_specialization,
    hcp_signature: data.hcp_signature,
    hcp_signature_filename: data.hcp_signature_filename,
    email_id: data.email_id,
    hcp_mobile: data.hcp_mobile,
  });
  const options = {
    method: "POST",
    headers: headers,
    body: attendeeData,
  };

  return fetch(
    `${backendConfig.backendEndpoint}attendee-updateattendeedetails`,
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
